
<script setup>
import { usePage } from '@inertiajs/vue3' 
import { CloseRound } from '@vicons/material'

const props = defineProps({
    closable: {
        type: Boolean,
        deafult: false
    },

    loadedUser: {
        type: Object,
        default: null
    },

    effects: {
        type: Object,
        default: null
    }
})

defineEmits([ 'close' ])

const userLogged = computed(() => usePage().props.auth.user)
const user = ref(null)
document.body.style.overflow = 'hidden'

const logo = ref(null)

const customTopColor = ref(null)
const customBottomColor = ref(null)
const effectHeight = ref('40px')
onBeforeMount(() => {
    if (props.loadedUser) {
        console.log('teste')
        user.value = props.loadedUser
    } else {
        user.value = userLogged.value
    }

    //esse if é pra a tela de edição do load da agencia
    if (props.effects) {
        if (props.effects.topColor) {
            customTopColor.value = props.effects.topColor
        }

        if (props.effects.bottomColor) {
            customBottomColor.value = props.effects.bottomColor
        }

        if (props.effects.effect_height) {
            effectHeight.value = props.effects.effect_height+'px'
        }
    } else {
        if (user.value) {
            //decidindo quais configurações de efeito usar
            let effects = null //por padrao nenhuma
            if (user.value.role == 'agency') {
                //se for uma agencia logada, usa a dela propria
                effects = user.value.load_effects
            } else if (user.value.agency_owners.length > 0) {
                //se for alguem que é filho de agencia no entanto, usa a da agencia dela
                const last = user.value.agency_owners.length - 1
                const owner = user.value.agency_owners[last]

                effects = owner.load_effects
            }

            if (effects) {
                customTopColor.value = effects.load_top_color
                customBottomColor.value = effects.load_bottom_color
                effectHeight.value = effects.effect_height+'px'
            }
        }
    }


    //custom logo aqui
    if (user.value) {
        if (user.value.role == 'agency') {
            logo.value = (user.value.logo) ? user.value.logo : '/images/logo-placeholder.png'
        } else {
            if (user.value.agency_owners.length > 0) {
                //não sendo uma agencia entao vai usar a logo da agencia que cadatrou o cliente
                const last = user.value.agency_owners.length - 1;
                const lastOwnerLogo = user.value.agency_owners[last].logo

                if (lastOwnerLogo) {
                    logo.value = lastOwnerLogo
                }
            }
        }
    }
})
</script>

<template>
    <Teleport to="body">
        <div class="loadingContainer">

            <div class="loading">
                <img
                    :src="logo"
                    v-if="logo"
                    class="tw-p-0 tw-object-fill logo"
                >
                <h1 class="logo" v-else>
                    <span class="!tw-text-blue-wc">Whats.</span>
                    <span class="!tw-text-green-wc">Company</span>
                </h1>
                
                <!--- 
                    top tw-bg-green-wc
                    bottom tw-bg-blue-wc
                -->
                <div 
                    v-for="i in 12" :key="i" 
                    class="strand"
                    :style="{
                        height: (effectHeight) ? effectHeight : '40px'
                    }"
                >
                    <div 
                        class="top" 
                        :style="{
                            'animation-delay': `${i * -0.2}s`,
                            background: (customTopColor) ? customTopColor :  '#02FF00'
                        }"
                    />
                    <div 
                        class="bottom" 
                        :style="{
                            'animation-delay': `${-1.5 - (i * 0.2)}s`,
                            background: (customBottomColor) ? customBottomColor : '#01A5ED'
                        }"
                    />
                </div>
            </div>
            
            <span 
                class="tw-absolute tw-top-[20px] tw-right-[20px] tw-w-auto tw-h-[40px] tw-rounded-md tw-shadow-md 
            tw-bg-blue-wc tw-text-white tw-flex tw-justify-center tw-items-center tw-px-3 tw-cursor-pointer" 
                v-if="closable"
                @click="$emit('close')"
            >
                <CloseRound class="tw-w-5 tw-mr-2"/>
                Fechar Exemplo
            </span>
        </div>
        <div class="loadingBackdrop" />
    </Teleport>
</template>

<style scoped>
.logo {
    @apply tw-flex tw-text-[25px] tw-font-bold tw-absolute;
    font-family: Arial, Helvetica, sans-serif;
}

.logo span {
    @apply !tw-p-0 tw-bg-transparent    
}

.loadingBackdrop {
    @apply tw-fixed tw-top-0 tw-left-0 tw-bg-white/90 tw-backdrop-blur-sm tw-z-[1000] tw-w-full tw-h-full;
    transition: opacity .2s ease-in-out;
}

.loadingContainer {
    @apply tw-fixed tw-top-0 tw-left-0 tw-z-[9999] tw-w-full tw-h-full tw-flex tw-flex-col 
    tw-items-center tw-justify-center tw-opacity-100;
}

.loading {
    @apply tw-flex tw-w-auto tw-h-[30px] tw-items-center tw-scale-[1.5]
}

.strand {
    position: relative;
    width: 15px;
}

.top {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 12px;
    width: 12px;
    border-radius: 100%;
    animation: 3s moveTop infinite ease-in-out;
}

.bottom {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 12px;
    width: 12px;
    border-radius: 100%;
    animation: 3s moveBottom infinite ease-in-out;
}

@keyframes moveTop {
    0% {
        top: 100%;
        z-index: -3;
    }

    25% {
        height: 8px;
        width: 8px;
        z-index: -3;
    }

    50% {
        top: 0%;
        z-index: -3;
    }

    75% {
        height: 12px;
        width: 12px;
        z-index: 3;
    }

    100% {
        top: 100%;
        z-index: 3;
    }
}

@keyframes moveBottom {
    0% {
        top: 100%;
        z-index: -3;
    }

    25% {
        height: 8px;
        width: 8px;
        z-index: -3;
    }

    50% {
        top: 0%;
        z-index: -3;
    }

    75% {
        height: 12px;
        width: 12px;
        z-index: 3;
    }

    100% {
        top: 100%;
        z-index: 3;
    }
}
</style>